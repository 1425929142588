<template>
<div class="invited-card-box">
	<div class="content">
		<div class="title">链接</div>
		<div class="info">分享链接快速邀请入驻{{platname}}</div>
		<div class="content1">
			{{share_content}}
		</div>
		<div class="btn-box">
			<el-button size="mini" 
				class="copy-btn tag-read" 
				:data-clipboard-text="share_content"  
				@click="copyContentBtn">复制</el-button>
		</div>
	</div>
	<div class="content">
		<div class="title">二维码</div>
		<div class="info">分享二维码邀请入驻{{platname}}</div>
		<div class="content1 img-code">
			<img :src="code_img" id="invitedImg" class="share-img" ref="choseImgs" alt="">
		</div>
		<div class="btn-box">
			<el-button size="mini" class="copy-btn tag-img" @click="invitSetBtn">复制</el-button>
		</div>
	</div>
</div>
</template>

<script>
	/* 普通租户：http://域名/invite/general/dfkd
	独立租户：http://域名/invite/alone/dfkd
	用户：http://域名/invite/member/dfkd
	员工：http://域名/invite/staff/dfkd */
	import Clipboard from 'clipboard'
	export default {
		props:{
			text:{ //邀请内容显示
				type:String,
			},
			code_url:{ //邀请二维码链接
				type:String
			}
		},
		data(){
			return {
				share_content:'',
				code_img:'',
				userInfo:{},
				platname:'',
			}
		},
		created(){
			this.share_content = this.text;
			if(this.code_url){
				this.getShareCode();
			}
			if(localStorage.platInfo){
				let platInfo = JSON.parse(localStorage.platInfo);
				this.platname = platInfo.name; 
			}
		},
		mounted(){
			
		},
		methods: {
			invitSetBtn(){
				function b64toBlob(b64Data, contentType = null, sliceSize = null) {
				  contentType = contentType || 'image/png'
				  sliceSize = sliceSize || 512;
				  let byteCharacters = window.atob(b64Data.substring(b64Data.indexOf(',') + 1))
				  let byteArrays = []
				  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
				    let slice = byteCharacters.slice(offset, offset + sliceSize)
				    let byteNumbers = new Array(slice.length);
				    for (let i = 0; i < slice.length; i++) {
				      byteNumbers[i] = slice.charCodeAt(i)
				    }
				    var byteArray = new Uint8Array(byteNumbers)
				    byteArrays.push(byteArray)
				  }
				  return new Blob(byteArrays, {type: contentType})
				}
				
				let _this = this;
				function clip(base64Data) {
						try {          
							if (document.body.createControlRange) { //IE 11 需要有个img标签
										var controlRange;
										var imgs = document.getElementById('invitedImg');
										imgs.onload = function () {
												controlRange = document.body.createControlRange();
												imgs.contentEditable = 'true';
												controlRange.addElement(imgs);
												try {
														var successful = controlRange.execCommand('copy');
														var msg = successful ? 'successful' : 'unsuccessful';
														console.log('Copying text command was ' + msg);
														_this.$message.success("复制成功");
												} catch (err) {
														console.log(err);
												}
										}
										imgs.src = 'data:image/png;base64,' + base64Data;
								}else { //chrome
										const blobInput = b64toBlob(base64Data, 'image/png');
										const clipboardItemInput = new ClipboardItem({ 'image/png': blobInput });
										navigator.clipboard.write([clipboardItemInput]);
										_this.$message.success("复制成功");
								}
						} catch (e) {
								console.log(e);
								 _this.$message.success("复制失败："+JSON.stringify(e));
						}
				}
				clip(this.code_img)
			},
			copyContentBtn(){
				var clipboard = new Clipboard('.tag-read');
				let _this = this;
				clipboard.on('success', e => {
					_this.$Message.success('复制成功');
				})
				clipboard.on('error', e =>{
					_this.$Message.error('该浏览器不支持复制');
				})
				this.clipboard = clipboard;
				setTimeout(()=>{
					this.clipboard.destroy &&this.clipboard.destroy()
				},500)
			},
			getShareCode(){
				let data = {
					content:this.code_url,
					img_size:100,
				}
				this.$utils.getQrcode(data).then(res=>{
					this.code_img = res.img
				}).catch(err=>{
					console.log(err);
				})
			},
		},
	}
</script>

<style scoped lang="scss">
	@mixin ellipsisText($num) {
		overflow: hidden;
		
	}
	@mixin flexCenter {
		display: flex;
		justify-content: center;
		align-items: center;
	}	
	$primary:#F26161;
	.invited-card-box{
		display: flex;
		.content{
			width:240px;
			height:246px;
			border:1px solid #efefef;
			padding:10px 12px;
			.title{
				font-weight: 650;
			}
			.info{
				font-size: 13px;
				color:#999999;
			}
			.content1{
				margin-top: 5px;
				word-break:break-all;
				background-color: #FAFAFA;
				padding:10px; 
				line-height: 26px;
				height: 140px;
				@include  ellipsisText(5);
				&.img-code{
					@include flexCenter();
				}
			}
			.btn-box{
				margin-top: 8px;
				text-align: right;
				.copy-btn{
					border-color:transparent;
					background-color: rgba($primary,.1);
					color:$primary;
				}
			}
		}
	}
</style>
